<template>
    <div class="container">
        <div class="text-container">
            <div class="text" id="fade-text">Hello 👋</div>
        </div>
        <ul class="social-media">
            <li><a href="https://www.linkedin.com/company/clockyco/" target="_blank">Linkedin</a></li>
            <li><a href="https://x.com/clockyco" target="_blank">Twitter</a></li>
            <li><a href="https://cases.media/en/clockyco/" target="_blank">Cases</a></li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import "styles/main";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
</style>
